<template>
  <div class="reg-form" :style="{ backgroundImage: backImg }">
    <!-- header  -->
    <b-row class="center-element" style="margin-right: -2px">
      <b-col lg="8" class="header">
        {{ $t('financialTransactionPayment') }}
      </b-col>
    </b-row>

    <!-- registeration content -->
    <b-row class="center-element">
      <b-col lg="8" style="padding-left: 0;">
        <b-card class="reg-card">
          <g-form v-if="!formSubmitedSuccessfully" @submit="saveInit">
            <!-- steps section  -->
            <b-row class="reg-steps center-element mb-3 mt-1">
              <div
                :class="
                  currentStep === stepsEnum.studentSection
                    ? 'active-step'
                    : 'nonActive-step'
                "
              >
                {{
                    $t('financialTransactionInformation')
                }}
              </div>
            </b-row>
            <!-- clientBranches Section -->
            <b-row
              v-if="currentStep === stepsEnum.studentSection"
              class="center-element flex-column"
            >
              <b-col
                v-for="branch in clientBranches"
                :key="branch"
                md="5"
                class="d-flex mb-1"
              >
                <b-form-radio
                  :id="branch.arabicName"
                  v-model="selectedItem.branchId"
                  :name="branch.arabicName"
                  :value="branch.id"
                  class="variant-primary"
                />
                <label
                  class="main-font-size ml-25 mr-1"
                  :for="branch.arabicName"
                >
                  {{ branch.arabicName }}
                </label>
              </b-col>
            </b-row>
            <!-- arabic and english Name Section -->
            <b-row v-if="currentStep === stepsEnum.studentSection" class="mb-1">
              <!-- arabic name  -->
              <b-col md="6">
                <g-field
                  :value.sync="selectedItem.arabicName"
                  rules="required"
                  label-text="arabicName"
                  disabled
                />
              </b-col>
              <!-- english name  -->
              <b-col md="6">
                <g-field
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  label-text="englishName"
                  disabled
                />
              </b-col>
            </b-row>

            <!-- personal inforamtions section -->
            <b-tabs
              v-if="currentStep === stepsEnum.studentSection"
              v-model="tabIndex"
              content-class="pt-2"
              pills
              fill
            >
              <b-tab
                :title="$t('personalData')"
                :active="tabIndex === studentInfoEnum.personalData"
              >
                <b-card>
                  <b-row>
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.stageNameAr"
                        rules="required"
                        label-text="stage"
                        disabled
                      />
                    </b-col>
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.rowNameAr"
                        rules="required"
                        label-text="row"
                        disabled
                      />
                    </b-col>
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.classroomNameAr"
                        rules="required"
                        label-text="classRoom"
                        disabled
                      />
                    </b-col>
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.remainingBalance"
                        rules="required"
                        label-text="studentBalance"
                        disabled
                      />
                    </b-col>
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.value"
                        type="number"
                        rules="required"
                        label-text="value"
                        disabled
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>

            <!-- save button -->
            <b-row v-if="currentStep === stepsEnum.studentSection">
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  :disabled="!selectedItem.id"
                  class="mx-1"
                  type="submit"
                  variant="relief-primary"
                >
                  {{
                    tabIndex === studentInfoEnum.personalData
                      ? $t('pay')
                      : $t('next')
                  }}
                  <feather-icon
                    :icon="
                      tabIndex === studentInfoEnum.personalData
                        ? 'SendIcon'
                        : 'ChevronLeftIcon'
                    "
                    class="ml-25"
                  />
                </b-button>
              </b-col>
            </b-row>
          </g-form>

          <b-row
            v-if="formSubmitedSuccessfully"
            class="center-element flex-column"
          >
            <img :src="confirmedImg" width="300px" alt="SavedSuccessfully" />
            <label style="font-size: 18px;">
              {{ $t('registerationRequestSentSuccessfully') }}
            </label>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="payForm-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      style="direction: ltr"
      size="lg"
      hide-footer
      :title="$t('pay')"
    >
      <h2 class="text-center mb-2">
        <span class="font-weight-bolder text-nowrap">
          {{ $t('net') }}
        </span>
        : {{ fraction(totalNet) }}
      </h2>
      <form
        style="direction: ltr"
        :action="getProductionUrl()"
        class="paymentWidgets"
        data-brands="VISA MASTER AMEX MADA"
      />
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" variant="secondary" @click="closeModal">
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
  },
  data() {
    return {
      otpId: 0,
      sendOtp: {},
      activationData: {
        oTPCode: '',
        id: '',
      },
      currentNumber: '',
      refno: '',
      init: {},
      clientBranches: [],
      nationalities: [],
      formSubmitedSuccessfully: false,
      selectedItem: {},
      currentStep: 3,
      stepsEnum: {
        identityNumberSection: 1,
        validateNumber: 2,
        studentSection: 3,
      },
      tabIndex: 0,
      studentInfoEnum: {
        personalData: 0,
        educationalData: 1,
        healthData: 2,
      },
      helper: {
        guardianMobile: null,
        guardianEmail: null,
      },
      prodctionUrl: `${document.location.origin}/#/paymentStatus/`,
    };
  },
  computed: {
    totalNet() {
      return this.selectedItem.value; // - this.remaining;
    },
    backImg() {
      return `url(${require('@/assets/images/background/RegisterationForm-bg.jpg')})`;
    },

    confirmedImg() {
      return require('@/assets/images/background/confirmed-bg.jpg');
    },

    clientDomain() {
      return document.location.host.split('.')[0];
    },
  },

  watch: {},

  mounted() {
    this.selectedItem.registrationDate = this.today;
    this.id = this.$route.params.id;
    this.loadData();
  },

  methods: {
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleOnComplete(value) {
      this.tabIndex = this.studentInfoEnum.personalData;
      this.activationData.oTPCode = value;
    },
    getProductionUrl() {
      // Replace with your logic to return the production URL
      return this.prodctionUrl + this.refno;
    },
    getBranches() {
      this.get({
        url: `RegistrationRequests/getBranchesForClient?identityNumber=${this.clientDomain}`,
      }).then((data) => {
        this.clientBranches = data;
      });
    },

    loadData() {
      this.get({
        url: `PayRequest/getFinancialTransaction?encryptedId=${this.id}&domain=${this.clientDomain}`,
      }).then((data) => {
        if (!data) {
          this.doneAlert({
            text: this.$t('identityNumberNOtMatchAnyStudnet'),
            type: 'error',
          });
        }
        this.selectedItem = data;
      });
    },

    isAllGuardianDataFilled() {
      if (!this.selectedItem.identityNumber) return false;
      return true;
    },
    sendOtps() {
      this.sendOtp.identityNumber = this.selectedItem.identityNumber;
      this.sendOtp.domain = this.clientDomain;
      this.create({ url: 'PayRequest/send-otp', data: this.sendOtp })
        .then((data) => {
          this.otpId = data.id;
          this.currentNumber = data.mobileNumber;
          this.currentStep += 1;
        })
        .catch(({ data }) => {
          this.doneAlert({ text: data.errors[0].message, type: 'error' });
        });
    },

    registrationDateCheck() {
      if (!this.selectedItem.registrationDate) {
        this.doneAlert({
          text: this.$t('registrationDateIsRequired'),
          type: 'warning',
        });
        return false;
      }
      return true;
    },

    birthDateCheck() {
      if (!this.selectedItem.birthDate) {
        this.doneAlert({
          text: this.$t('birthDateIsRequired'),
          type: 'warning',
        });
        return false;
      }
      return true;
    },

    stagesCheck() {
      if (
        this.selectedItem.stageId === null || this.selectedItem.rowId === null
      ) {
        this.doneAlert({ text: this.$t('stagesIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    prepareForAdd() {
      this.selectedItem.clientDomain = this.clientDomain;
      if (this.selectedItem.noorId) this.selectedItem.registeredInNoor = true;
      this.selectedItem.contactContent = this.selectedItem.contactWithMobile
        ? this.helper.guardianMobile
        : this.helper.guardianEmail;
    },
    saveInit() {
      if (this.tabIndex !== this.studentInfoEnum.personalData) {
        this.tabIndex += 1;
        return;
      }
      if (!this.selectedItem.value || Number(this.selectedItem.value) <= 0) {
        this.doneAlert({
          text: this.$t('valueMustBeGreaterThanZero'),
          type: 'warning',
        });
        return;
      }
      this.init = this.selectedItem;
      this.create({ url: 'PayRequest/init', data: this.init })
        .then((data) => {
          this.refno = data;
          this.openModal(data);
          localStorage.CHECKOUT_ID = JSON.stringify(data.value);
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        });
    },
    closeModal() {
      this.$refs['payForm-modal'].hide();
    },
    openModal(data) {
      this.$refs['payForm-modal'].show();
      const paymentWidgets = document.createElement('script');
      paymentWidgets.setAttribute('src', `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${data}`);
      document.head.appendChild(paymentWidgets);
    },
    save() {
      if (this.tabIndex !== this.studentInfoEnum.personalData) {
        this.tabIndex += 1;
        return;
      }

      if (!this.birthDateCheck()) return;
      if (!this.registrationDateCheck()) return;
      if (!this.stagesCheck()) return;

      this.prepareForAdd();

      this.create({
        url: 'RegistrationRequests',
        data: this.selectedItem,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('registerationRequestSentSuccessfully'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        });
        this.formSubmitedSuccessfully = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.reg-form {
  min-height: 100vh;
  background-size: 250px;
}
.reg-form legend,
.reg-form a,
.reg-form label,
.reg-form span {
  letter-spacing: 1.3px;
  color: black;
}
.reg-form input,
.reg-form small,
.reg-form textarea,
.reg-form button {
  letter-spacing: 1px;
}
.reg-form input,
.reg-form textarea {
  color: rgb(104, 102, 102);
}
.reg-form .nav-link.active {
  background: #7367f0 !important;
}

.reg-form .nav-link {
  border-color: #7367f0;
  margin: 3px;
}

.reg-form .header {
  background-color: #7367f0;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 33px;
  letter-spacing: 2px;
  text-align: center;
  box-shadow: 0 0 3px 0 #5e7b85;
}

.reg-form .active-step,
.reg-form .nonActive-step {
  height: 40px;
  letter-spacing: 1.2px;
  text-align: center;
  padding: 10px 10px 0;
  border: 1px solid #7367f0;
  border-radius: 5px;
  cursor: default;
}

.reg-form .active-step {
  background-color: #7367f0;
  color: #fff;
}

.reg-form .nonActive-step {
  background-color: #fff;
  color: #7367f0;
}
.reg-form .reg-card {
  box-shadow: 0 0 3px 0 #5e7b85 !important;
}
.reg-form .reg-steps span {
  color: #7367f0;
  width: 100px;
  border: dashed 1px;
}
.reg-form .guardian-hint {
  font-size: 11px;
  color: red;
  display: block;
  margin-bottom: 15px;
  margin-top: -7px;
}

/* in large screens  */
@media (max-width: 1400px) {
  .reg-form {
    background-image: none;
  }
  .reg-form .reg-steps {
    flex-direction: column;
  }
  .reg-form .reg-steps span {
    width: 1px;
    height: 30px;
  }
}

.d-inline-button > .col {
  display: flex !important;
}

.wpwl-form {
  direction: rtl !important;
}
</style>
